import React, { useState, useEffect } from "react";

const CountdownTimer = ({ targetDate }) => {
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(targetDate));

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft(targetDate));
        }, 1000);

        return () => clearInterval(timer);
    }, [targetDate]);

    function calculateTimeLeft(targetDate) {
        const now = new Date();
        const difference = new Date(targetDate) - now;
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
                minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
                seconds: Math.floor((difference % (1000 * 60)) / 1000),
            };
        }

        return timeLeft;
    }

    const style = {
        container: {
            display: 'flex',
            alignItems: 'center',
            fontFamily: 'Arial, sans-serif',
            color: '#fff',
            textAlign: 'center',
            gap: '10px',
            paddingTop: '2em'
        },
        timeUnit: {
            fontSize: '2rem',
            fontWeight: 'bold',
            margin: '0 10px',
            padding: '8px',
            backgroundColor: '#cba35c',

        },
        label: {
            fontSize: '1rem',
            color: '#ddd',
        },
    };

    return (
        <div style={style.container}>
            <div>
                <span style={style.timeUnit}>{timeLeft.days}</span>
                <span style={style.label}>Days</span>
            </div>
            <div>
                <span style={style.timeUnit}>{timeLeft.hours}</span>
                <span style={style.label}>Hours</span>
            </div>
            <div>
                <span style={style.timeUnit}>{timeLeft.minutes}</span>
                <span style={style.label}>Minutes</span>
            </div>
            <div>
                <span style={style.timeUnit}>{timeLeft.seconds}</span>
                <span style={style.label}>Seconds</span>
            </div>
        </div>
    );
};

export default CountdownTimer;
