
import Load from "./pages/Load";
import './styles.css'

function App() {
  return (
    <div>
      <Load />
    </div>
  );
}

export default App;
