import React from "react";
import CountdownTimer from "../components/CountdownTimer";

export default function Load() {
    const targetDate = "2024-11-30T23:59:59";
    return (
        <div className="title"
        >
            <h1>#YomifoundhisKevwe</h1>
             <p
             style={{
                fontSize: '3em'
             }}>30.11.2024</p>
            <CountdownTimer targetDate={targetDate} />


        </div>
    )
}